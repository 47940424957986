import { writable } from 'svelte/store'
import waitForEvent from 'wait-for-event-promise'
import { isFixedTestTableMode } from './table'
import { funhash } from '../../shared/lib/misc'

if (!window.localStorage.getItem('yaoOrderDeviceIdSuffix') && window.localStorage.getItem('yaoKioskDeviceIdSuffix')) {
  // Legacy support
  window.localStorage.setItem('yaoOrderDeviceIdSuffix', window.localStorage.getItem('yaoKioskDeviceIdSuffix'))
  window.localStorage.removeItem('yaoKioskDeviceIdSuffix')
}

if (!window.localStorage.getItem('yaoOrderDeviceIdSuffix')) {
  window.localStorage.setItem('yaoOrderDeviceIdSuffix', Math.floor(Math.random() * 10000).toString().padStart(4, '0'))
}

export const deviceIdSuffix = window.localStorage.getItem('yaoOrderDeviceIdSuffix')
export let deviceId = null

export let isYaoKiosk = null

export let paymentDisabled = null

export const batteryStatus = writable(null)
export const brightness = writable(100)

window.addEventListener('message', e => {
  if (e.source !== window.parent || e.source === window) return

  console.log('Received message', e.data)

  if (e.data.action === 'batteryStatusResult' && e.data.data) {
    batteryStatus.set(e.data.data)
  }

  if (e.data.action === 'nfcTag' && e.data.data) {
    window.dispatchEvent(new CustomEvent('nfcTag', { detail: { id: (funhash(e.data.data.id) % 1e6).toString().padStart(6, '0'), rawId: e.data.data.id } }))
  }

  if (e.data.action === 'employeeMenu') {
    window.dispatchEvent(new CustomEvent('employeeMenuTrigger', { detail: null }))
  }
})

export async function sendAppMessage (action, data, timeout = 2000) {
  if (!window.parent || window.parent === window) throw new Error('Not in iframe')

  const id = Math.random().toString(36).substring(7)
  const fullMessage = { id, action, data }
  if (action === 'setBrightness') fullMessage.brightness = data.brightness // For compatibility with YaoKiosk version 1.1.1
  console.log('Sending message', fullMessage)
  window.parent.postMessage(fullMessage, '*')

  if (timeout === false) return // No response expected
  const response = await waitForEvent(window, 'message', e => e.data.id === id, timeout !== null ? { timeout } : undefined)
  if (response.data.error) {
    throw response.data.error
  } else {
    return response.data.data
  }
}

if (!window.$yo) window.$yo = {}
window.$yo.sendAppMessage = sendAppMessage

export async function getSettings () {
  return await sendAppMessage('getSettings')
}

export async function updateSettings (data) { // This will reload the page!
  return await sendAppMessage('updateSettings', data)
}

export async function getBrightness () {
  return Number(await sendAppMessage('getBrightness'))
}

export async function setBrightness (value) {
  if (value < 0 || value > 100) throw new Error('Invalid brightness value')
  return await sendAppMessage('setBrightness', { brightness: Math.max(0.04, value / 100) }, false)
}

export async function getBatteryStatus () {
  return await sendAppMessage('batteryStatus')
}

export async function executePayment (amount) {
  if (paymentDisabled) throw new Error('Payment is disabled')
  if (amount < 0.01) throw new Error('Amount must be at least 0.01')
  if (executePayment.running) throw new Error('Payment already in progress')

  try {
    executePayment.running = true
    const data = await sendAppMessage('payment', { amount: Math.round(amount * 100) }, null)

    try {
      const parsedData = JSON.parse(data)
      const txResult = JSON.parse(parsedData.txResult)
      const txDetails = JSON.parse(parsedData.txDetails)
      const stateResult = JSON.parse(parsedData.stateResult)
      return { ...txResult, ...txDetails, state: stateResult }
    } catch (e) {
      throw new Error(`Failed to parse payment result: ${data}`)
    }
  } finally {
    executePayment.running = false
  }
}

export async function getLastTransaction () {
  const data = await sendAppMessage('getLastTransaction', {}, 15000)

  try {
    const parsedData = JSON.parse(data)
    const txDetails = JSON.parse(parsedData.txDetails)
    const stateResult = JSON.parse(parsedData.stateResult)
    return { ...txDetails, state: stateResult }
  } catch (e) {
    throw new Error(`Failed to parse payment result: ${data}`)
  }
}

export async function resetScreen () {
  try {
    await sendAppMessage('resetScreen')
  } catch (e) {
    console.error('Failed to reset screen', e)
  }
}

export async function openGpTom () {
  await sendAppMessage('openGPTom')
}

export async function initYaoKiosk () {
  let deviceIdPrefix

  try {
    const params = new URLSearchParams(window.location.search)
    const settings = await getSettings()
    await getBatteryStatus().catch(() => {})
    isYaoKiosk = true
    deviceIdPrefix = settings.tableNumber || 'X'
    paymentDisabled = params.has('disablePayment')

    if (params.has('fixedBrightness')) {
      setBrightness(Number(params.get('fixedBrightness')))
    } else {
      brightness.subscribe($brightness => {
        setBrightness($brightness)
      })
    }
  } catch (e) {
    console.error('Failed to initialize YaoKiosk', e)
    isYaoKiosk = false
    deviceIdPrefix = 'W'
    paymentDisabled = true
  }

  if (isFixedTestTableMode) deviceIdPrefix = 'T' + deviceIdPrefix

  deviceId = `${deviceIdPrefix}-${deviceIdSuffix}`

  return isYaoKiosk
}
