<script>
  import { tC } from '../stores/i18n'
  import { doZoomImage, formatCurrency, formatTime, getDescriptionPreview, getMediaUrl, handleOverscroll, rippleOnTouch, timeRestrictionMatches, validateArticleConfiguration } from '../lib/utils'
  import CustomerLanguageSelector from './CustomerLanguageSelector.svelte'
  import session, { articleConfigurations, saveArticleConfiguration, selectedCategoryId, selectedArticle, selectedArticleId, cart, wishlist, history, userData, isMatchingFilter, updateAgeVerificationRequest } from '../stores/session'
  import { fade, fly, slide, scale } from 'svelte/transition'
  import bundle from '../stores/bundle'
  import FontSizeSelector from './FontSizeSelector.svelte'
  import ArticleDetails from './ArticleDetails.svelte'
  import Debouncer from '../lib/Debouncer'
  import { onMount, tick } from 'svelte'
  import NewBadge from './NewBadge.svelte'
  import { second, minute } from '../stores/timer'
  import BrandButtonRow from './BrandButtonRow.svelte'
  import BrandButton from './BrandButton.svelte'
  import CartView from './CartView.svelte'
  import ShoppingCart from 'svelte-feather/components/ShoppingCart.svelte'
  import Heart from 'svelte-feather/components/Heart.svelte'
  import Plus from 'svelte-feather/components/Plus.svelte'
  import Minus from 'svelte-feather/components/Minus.svelte'
  import Copy from 'svelte-feather/components/Copy.svelte'
  import X from 'svelte-feather/components/X.svelte'
  import ArrowLeft from 'svelte-feather/components/ArrowLeft.svelte'
  import ArrowRight from 'svelte-feather/components/ArrowRight.svelte'
  import Sliders from 'svelte-feather/components/Sliders.svelte'
  import PillTabs from './PillTabs.svelte'
  import qclone from 'qclone'
  import MobileTooltip from './MobileTooltip.svelte'
  import Recommendations from './Recommendations.svelte'
  import { cubicOut } from 'svelte/easing'
  import { getOptionLabel, diets } from '../../payload/i18nConstants'
  import dialogs from '../stores/dialogs'
  import DietFilterDialog from './DietFilterDialog.svelte'
  import AllergenFilterDialog from './AllergenFilterDialog.svelte'
  import { Toast } from 'svelma-fixed'
  import Robot from './icons/Robot.svelte'
  import { table, isMenuPreviewMode } from '../stores/table'
  import { apiCall } from '../lib/api'
  import MessageDialog from './MessageDialog.svelte'
  import AlertTriangle from 'svelte-feather/components/AlertTriangle.svelte'
  import ConfirmSubArticlesDialog from './ConfirmSubArticlesDialog.svelte'

  const categoryScrollDebouncer = new Debouncer(350, true)

  let navSnappy = true
  let navElement
  let menuElement
  let detailsAreaElement
  let articleDetailsElTmp
  let articleDetailsEl

  $: categories = $table?.type === 'kiosk' ? $bundle.categories.filter(category => category.articles.some(article => article?.takeawayAvailability !== 'hidden')) : $bundle.categories
  // TODO: This is a hacky workaround for a possible bug caused by binding components inside {#key} tags. Remove it after migrating to a newer Svelte version
  $: if (articleDetailsElTmp) articleDetailsEl = articleDetailsElTmp

  let currentlyScrollingToCategory

  function selectCategory (categoryId, doNotScroll = false) {
    closeCartItemEditor()

    if (!categoryId) categoryId = $bundle.categories[0]?.id

    if (currentlyScrollingToCategory && doNotScroll) return // This will be triggered on scroll end instead
    if (!doNotScroll) currentlyScrollingToCategory = categoryId

    $selectedCategoryId = categoryId

    categoryScrollDebouncer.debounce(() => {
      const navChildEl = navElement.querySelector(`[data-category-id="${categoryId}"]`)
      if (navChildEl) {
        navElement.scrollTo({
          top: navChildEl.offsetTop - 60
        })
      }
    })

    if (!doNotScroll) {
      const menuChildEl = menuElement.querySelector(`[data-category-id="${categoryId}"]`)
      if (menuChildEl) {
        menuElement.scrollTo({
          top: menuChildEl.offsetTop - Math.round(window.getComputedStyle(menuChildEl).marginTop.replace('px', ''))
        })
      }
    }
  }

  function selectArticle (articleId, doNotScroll = false) {
    closeCartItemEditor()

    $selectedArticleId = articleId ?? $bundle.categories[0]?.articles[0]?.id

    if (!doNotScroll) {
      const articleElement = menuElement.querySelector(`[data-article-id="${$selectedArticleId}"]`)
      if (articleElement) {
        const extraOffset = articleElement.offsetParent === menuElement ? 0 : articleElement.offsetParent.offsetTop
        menuElement.scrollTo({
          top: Math.max(articleElement.offsetTop + extraOffset - 85, 150) // 150 = overscroll height
        })
      }
    }

    tick().then(() => {
      if ($selectedArticle?.ageRestriction > 0) updateAgeVerificationRequest(true).catch(() => {})
    })
  }

  onMount(() => {
    setTimeout(() => {
      selectCategory($selectedCategoryId, true)
      selectArticle($selectedArticleId)
    }, 200)
  })

  function updateSelectedCategory () {
    // Also hide any ripple effects, seems they were not intended because now we scroll!
    menuElement.querySelectorAll('.ripple-container').forEach(container => container.remove())

    const categoryElements = menuElement.querySelectorAll('.category')
    if (!categoryElements.length) return

    let categoryId = categoryElements[0].dataset.categoryId

    const lastCategoryElement = categoryElements[categoryElements.length - 1]
    if (lastCategoryElement.offsetTop + lastCategoryElement.offsetHeight < menuElement.scrollTop + menuElement.offsetHeight) {
      // Last category is fully visible
      categoryId = lastCategoryElement.dataset.categoryId
    } else {
      for (const categoryElement of categoryElements) {
        if (categoryElement.offsetTop > menuElement.scrollTop + 60) break
        categoryId = categoryElement.dataset.categoryId
      }
    }

    if (categoryId !== $selectedCategoryId) selectCategory(categoryId, true)
  }

  function onNavScrollEnd () {
    if (currentlyScrollingToCategory) {
      selectCategory(currentlyScrollingToCategory, true)
      currentlyScrollingToCategory = null
    }
  }

  let articleConfiguration = null
  $: if (articleConfiguration?.article !== $selectedArticle?.id) {
    articleConfiguration = $articleConfigurations.find(a => a.article === $selectedArticle?.id)

    if (!articleConfiguration) {
      articleConfiguration = {
        article: $selectedArticle?.id,
        subArticles: [],
        comment: ''
      }

      for (const group of $selectedArticle?.mergedGroups ?? []) {
        for (const { article, quantity } of group.initialSelection) {
          if ($bundle.articles[article]?.available) articleConfiguration.subArticles.push({ article, quantity, subArticleGroupId: group.id })
        }
      }
    }

    let dirty = false
    for (const subArticleInfo of articleConfiguration.subArticles) {
      const subArticle = $bundle.articles[subArticleInfo.article]
      if (!subArticle?.available || !$isMatchingFilter(subArticle)) {
        articleConfiguration.subArticles = articleConfiguration.subArticles.filter(subArticle => subArticle !== subArticleInfo)
        dirty = true
      }
    }

    if (dirty) saveArticleConfiguration(articleConfiguration)
  }

  $: configValidation = validateArticleConfiguration(articleConfiguration, $bundle, $table?.type)

  function addToCart () {
    if (!configValidation.isValid) return

    selectedCartView = 'cart'

    setTimeout(async () => {
      const getItemKey = item => [item.article, ...item.subArticles.map(subArticle => [subArticle.subArticleGroupId, subArticle.article, subArticle.quantity].join(':')).sort(), (item.comment ?? '')].join(';')

      const articleInfo = $bundle.articles[articleConfiguration.article]
      const subArticles = articleInfo.mergedGroups
      const filteredSubArticles = subArticles.filter(group => group.effectiveSelectionMode === 'multiple' && group.articleGroup.articles.length > 0)

      for (const group of filteredSubArticles) {
        if (group.confirmNonSelection && articleConfiguration.subArticles.findIndex(subArticle => subArticle.subArticleGroupId === group.id) === -1) {
          const items = group.articleGroup.articles.slice(0, 2).map(article => $tC(article.name))

          group.articleGroup.name.en = group.articleGroup.name.en.toLowerCase() // Keep original capitalization of German translation from CMS

          const confirm = await dialogs.open(ConfirmSubArticlesDialog, { article: $tC(articleInfo.name), group: $tC(group.articleGroup.name), items })

          if (confirm === null) {
            return
          } else if (confirm === false) {
            const articleElement = detailsAreaElement.querySelector('article')
            const groupElement = detailsAreaElement.querySelector(`[data-subgroup-id="${group.id}"]`)
            articleElement.scrollTo({
              top: groupElement.offsetTop - Math.round(window.getComputedStyle(groupElement).marginTop.replace('px', '')) - 77 // 77 = overscroll top padding
            })
            if (group.displayMode === 'overlay') {
              articleDetailsEl.showSelectionPopup(group.id)
            }

            return
          }
        }
      }

      cart.update(cart => {
        const existingIndex = cart.findIndex(item => getItemKey(item) === getItemKey(articleConfiguration))
        if (existingIndex !== -1) {
          cart[existingIndex].quantity++
        } else {
          cart.push({ ...qclone(articleConfiguration), quantity: 1, uuid: crypto.randomUUID() })
        }
        return cart
      })
    }, 0)
  }

  function toggleWishlist () {
    if (!$wishlist.some(item => item.article === $selectedArticle.id)) selectedCartView = 'wishlist'

    setTimeout(() => {
      wishlist.update(wishlist => {
        const existingIndex = wishlist.findIndex(item => item.article === $selectedArticle.id)
        if (existingIndex !== -1) {
          wishlist.splice(existingIndex, 1)
        } else {
          wishlist.push({ article: $selectedArticle.id, subArticles: [], uuid: crypto.randomUUID() })
        }
        return wishlist
      })
    }, 0)
  }

  let selectedCartView = 'cart'
  let currentlyEditingCartItemUuid = null
  let currentlyEditingCartItem

  $: {
    if (selectedCartView !== 'cart') closeCartItemEditor()

    currentlyEditingCartItem = $cart.find(item => item.uuid === currentlyEditingCartItemUuid)
    if (currentlyEditingCartItemUuid && !currentlyEditingCartItem) currentlyEditingCartItemUuid = null
  }

  $: cartEditingValidation = currentlyEditingCartItem ? validateArticleConfiguration(currentlyEditingCartItem, $bundle) : null

  $: recommendations = $selectedArticle?.recommendations?.flatMap(r => r.relationTo === 'articleGroups' ? r.value.articles : r.value).filter(a => a?.available && $bundle.categories.some(category => category.available && timeRestrictionMatches(category) && category.articles.includes(a)) && a.id !== $selectedArticle.id).filter((a, i, arr) => arr.findIndex(b => b.id === a.id) === i) ?? []

  function editCartItem (uuid) {
    currentlyEditingCartItemUuid = uuid
  }

  function closeCartItemEditor () {
    currentlyEditingCartItemUuid = null
  }

  function onCartItemClick ({ detail: item }) {
    if (selectedCartView === 'cart') {
      editCartItem(item.uuid)
    } else {
      selectArticle(item.article)
    }
  }

  function updateCartItem (item) {
    cart.update(cart => {
      const existingIndex = cart.findIndex(i => i.uuid === item.uuid)
      if (item.quantity > 0) {
        if (existingIndex !== -1) {
          cart[existingIndex] = item
        } else {
          cart.push(item)
        }
      } else if (existingIndex !== -1) {
        cart.splice(existingIndex, 1)
      }
      return cart
    })
  }

  function positionTriangle (element) {
    const listContainer = document.querySelector('.cart-area .list-container')

    const reposition = () => {
      const cartItemTargetElement = listContainer.querySelector(`li[data-uuid="${currentlyEditingCartItemUuid}"] .quantity`)
      let top = -9999
      if (cartItemTargetElement) {
        top = cartItemTargetElement.getBoundingClientRect().top - (element.offsetParent?.getBoundingClientRect().top ?? 0)
      }

      element.style.top = `${top}px`
    }

    reposition()

    // Whenever the cart item moves, reposition the triangle
    const listener = listContainer.addEventListener('scroll', reposition, { passive: true })

    const unsubscribeSession = session.subscribe(reposition)
    const unsubscribeBundle = bundle.subscribe(reposition)

    return {
      destroy () {
        listContainer.removeEventListener('scroll', listener)
        unsubscribeSession()
        unsubscribeBundle()
      }
    }
  }

  function saveCartChange () {
    // Currently, nothing needs to be done here, as session syncs automatically
  }

  function updateEditorQuantity (quantity) {
    currentlyEditingCartItem.quantity = quantity
    updateCartItem(currentlyEditingCartItem)
    saveCartChange()
  }

  function copyConfig (item = currentlyEditingCartItem) {
    const copiedArticleConfiguration = qclone({ article: item.article, subArticles: item.subArticles, comment: item.comment })
    if (articleConfiguration?.article === item.article) {
      articleConfiguration = copiedArticleConfiguration
      saveArticleConfiguration(copiedArticleConfiguration)
    } else {
      saveArticleConfiguration(copiedArticleConfiguration)
      selectArticle(item.article)
    }

    closeCartItemEditor()
  }

  let filterOpen = false

  function flatten (node, options) {
    return {
      duration: options.duration,
      easing: cubicOut,
      css: t => `transform:scaleY(${t});`
    }
  }

  $: allergenFilterCount = $userData?.allergenFilter?.length
  $: dietFilterCount = (($userData?.dietFilter ?? 'omnivore') !== 'omnivore') ? 1 : 0
  $: totalFilterCount = allergenFilterCount + dietFilterCount

  async function addExtraItemToCart (item) {
    copyConfig(item)
    await tick()
    addToCart()

    Toast.create({ message: $tC({ de: 'Artikel wurde in den Warenkorb gelegt!', en: 'Article was added to cart!' }), type: 'is-success', duration: 6000 })
  }

  $: callRobotOptionAvailable = !!$session.orders.length && !!$table?.robotPointId && $bundle.settings.powerSwitch.robotsEnabled

  function robotCallError () {
    dialogs.open(MessageDialog, { title: $tC({ de: 'Hinweis', en: 'Notice' }), text: $tC({ de: 'Leider konnte der Roboter nicht gerufen werden! Bitte wenden Sie sich an einen Mitarbeiter.', en: 'Unfortunately the robot could not be called! Please contact a member of staff.' }), iconClass: 'has-text-danger', Icon: AlertTriangle })
    $session.lastRobotCall = null
  }

  async function callRobot () {
    $session.lastRobotCall = new Date().toISOString()
    try {
      await apiCall('POST', '/api/app/callRobot', { sessionId: $session.id })
    } catch (e) {
      console.error('Failed to call robot', e)
      $session.lastRobotCall = null

      robotCallError()
    }
  }

  function getMenuItemUnavailabilityStatus (article, category) {
    if (!article.available || !category.available) return $tC({ de: 'Nicht verfügbar', en: 'Not Available' })

    if ($table?.type === 'kiosk' && article?.takeawayAvailability === 'disabledWithInfo') {
      return $tC({ de: 'Kein take-away', en: 'No take-away' })
    }

    return null
  }

  function isHiddenForTakeaway (article) {
    return $table?.type === 'kiosk' && !!article && article.type === 'main' && article?.takeawayAvailability === 'hidden'
  }

  function isUnavailableForTakeaway (article) {
    return $table?.type === 'kiosk' && !!article && article.type === 'main' && (article?.takeawayAvailability === 'disabledWithInfo' || article?.takeawayAvailability === 'hidden')
  }

</script>

<style lang="scss">
  .snappy {
    scroll-snap-type: y mandatory;
  }

  main {
    color: $text;
    background-color: white;

    width: 100%;
    height: 100%;

    $top-height: 150px;

    display: grid;
    grid-template-columns: 42fr 32fr 24fr;
    grid-template-rows: $top-height 1fr;
    grid-template-areas:
      "product-image nav logo"
      "details menu cart";

    > * {
      // Prevent grid blowout
      min-width: 0;
      min-height: 0;
    }

    .product-image-area {
      grid-area: product-image;
      overflow: visible;
      display: grid;
      place-items: center;
      grid-template-columns: 100%;
      grid-template-rows: 100%;
      cursor: zoom-in;
      z-index: 2;

      $zoom: 2;

      h1 {
        grid-area: 1 / 1 / 2 / 2;
        align-self: center;
        justify-self: start;
        margin: 0;
        margin-left: calc(2 * $default-margin);
        text-transform: uppercase;
        font-size: 4rem;
      }

      .product-image { // Using an actual img tag caused issues with cached images
        grid-area: 1 / 1 / 2 / 2;
        width: calc(100% / $zoom);
        height: 100%;
        background: var(--bg) no-repeat center center;
        background-size: contain;
        transform: scale($zoom);
        pointer-events: none;
        z-index: 1;

        &[data-unavailable]::after {
          transform: rotate(-10deg) scale(calc(1 / $zoom));
        }
      }
    }

    .nav-area {
      grid-area: nav;
      overflow: hidden;

      nav {
        position: relative; // For offsetTop
        font-size: 2.5rem;
        font-weight: bold;
        text-transform: uppercase;
        overflow-y: auto;
        width: 100%;
        height: 100%;
        scroll-behavior: smooth;
        padding: 0 0.5rem;

        $margin: 0.45em;

        .overscroller {
          height: calc(150px + 0.5em);
          margin: $margin 0;
        }

        a {
          display: block;
          color: $text;
          line-height: 1.2;
          margin: #{-$margin - 0.2em} 0 (-$margin);
          padding-top: 0.2em;
          scroll-snap-align: center;

          span {
            display: inline-block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 100%;
            position: relative;
          }

          &.is-selected {
            color: $brand-color;

            span::after {
              // Underline
              content: '';
              position: absolute;
              display: block;
              left: 0.05em;
              right: 0.05em;
              bottom: 0.15em;
              height: 0.075em;
              background-color: $brand-color;
            }
          }
        }
      }
    }

    .logo-area {
      grid-area: logo;
      padding: $default-margin;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        cursor: pointer;
      }
    }

    $info-bar-height: 2.5rem;

    // This element exists twice, once in the details area and once in the menu area, to solve z-index problems
    .info-bar {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: $info-bar-height;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $brand-color;
      color: white;
      z-index: 1;
      padding: 8px;
      padding-right: calc(24% + 8px); // 24% is the width of the cart area
      box-shadow: 0 0 10px $field-bg;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% + 16px);
        height: calc(100% + 16px);
        margin: -8px;
        color: white;

        &:hover {
          opacity: 0.9;
        }
      }

      :global(svg) {
        display: inline-block;
        width: auto;
        height: 1.5em;
        margin-top: -0.1em;
        margin-right: 0.5em;
      }

      span {
        font-weight: bold;
        font-size: 1.15em;
        text-transform: uppercase;
        line-height: 0.95;
      }

      .dot-dot-dot {
        display: inline-block;
        width: 1em;
        height: 0.95em;

        @keyframes dot-dot-dot {
          0% {
            content: '\00a0';
          }
          25% {
            content: '.';
          }
          50% {
            content: '..';
          }
          75%, 100% {
            content: '...';
          }
        }

        &::after {
          content: '';
          animation: dot-dot-dot 1s infinite steps(1);
        }
      }
    }

    .details-area {
      grid-area: details;

      background: $field-bg;
      border-top: 2px solid $brand-color;
      overflow: hidden;
      hyphens: auto;

      position: relative;
      display: flex;
      flex-direction: column;

      $fade-out-height: 77px;

      &::after {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: $fade-out-height;
        background: linear-gradient(to top,
          rgba($field-bg, 0) 0%,
          rgba($field-bg, 0.005) 2%,
          rgba($field-bg, 0.01) 4%,
          rgba($field-bg, 0.02) 6%,
          rgba($field-bg, 0.035) 8%,
          rgba($field-bg, 0.05) 10%,
          rgba($field-bg, 0.125) 12%,
          rgba($field-bg, 0.25) 14%,
          rgba($field-bg, 0.4) 16%,
          rgba($field-bg, 0.6) 20%,
          rgba($field-bg, 0.75) 30%,
          rgba($field-bg, 0.9) 40%,
          rgba($field-bg, 0.95) 50%,
          rgba($field-bg, 1) 75%,
          rgba($field-bg, 1) 100%
        );

        pointer-events: none;
        z-index: 1;
      }

      article {
        width: 100%;
        flex: 1;
        padding: $fade-out-height $default-margin $default-margin;
        overflow-x: hidden;
        overflow-y: auto;

        transition: padding-bottom 0.4s;

        min-height: calc(100vh - $top-height - 2px); // This is required to make the fly animation work correctly with the sticky bottom button bar. 152 = nav area size + blue border

        &.has-info-bar {
          padding-bottom: calc($default-margin + $info-bar-height);
        }

        &:has(.recommendations) :global(.button-container) {
          bottom: calc(0px - $default-margin - 16px - 95px);
        }
      }

      .button-container {
        position: sticky;
        bottom: 0;
        margin-left: -0.5rem;
        margin-right: -0.5rem;
        z-index: 1;

        $fade-out-height: calc($default-margin * 1.25);

        padding: calc($fade-out-height * 0.85) 0.5rem 0;

        &::before {
          content: '';
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          width: 100%;
          height: $fade-out-height;
          background: linear-gradient(to bottom,
            rgba($field-bg, 0) 0%,
            rgba($field-bg, 0.005) 2%,
            rgba($field-bg, 0.01) 4%,
            rgba($field-bg, 0.02) 6%,
            rgba($field-bg, 0.035) 8%,
            rgba($field-bg, 0.05) 10%,
            rgba($field-bg, 0.125) 12%,
            rgba($field-bg, 0.25) 14%,
            rgba($field-bg, 0.4) 16%,
            rgba($field-bg, 0.6) 20%,
            rgba($field-bg, 0.75) 30%,
            rgba($field-bg, 0.9) 40%,
            rgba($field-bg, 0.95) 50%,
            rgba($field-bg, 1) 75%,
            rgba($field-bg, 1) 100%
          );

          z-index: -1;
          pointer-events: none;
        }

        &::after {
          content: '';
          position: absolute;
          display: block;
          left: 0;
          top: calc($fade-out-height - 1px);
          width: 100%;
          height: calc(100% - $fade-out-height + 1px + $default-margin);
          background: $field-bg;

          z-index: -1;
        }

        > * {
          position: relative;
          z-index: 0;
        }

        :global(.feather-heart) {
          transition: fill 0.2s;
          fill: transparent;
        }

        :global(.brand-button.is-selected .feather-heart) {
          fill: currentColor;
        }
      }
    }

    .menu-area {
      grid-area: menu;
      position: relative;
      z-index: 1;

      background: $field-bg;
      border-top: 2px solid $brand-color;

      nav.menu {
        width: 100%;
        height: 100%;
        position: relative; // For offsetTop
        padding: 0 0.5rem $default-margin;
        overflow-x: hidden;
        overflow-y: auto;
        scroll-behavior: smooth;

        transition: padding-bottom 0.4s;
        &.has-info-bar {
          padding-bottom: calc($default-margin + $info-bar-height);
        }

        .category {
          margin-top: 0.8rem;
        }

        h2 {
          text-transform: uppercase;
          color: $brand-color;
          margin-top: 0;
          margin-bottom: 0.25em;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          &.is-unavailable {
            color: $grey;
          }
        }

        .category-subtitle {
          font-size: 0.75em;
          transform: translateY(-1em);
        }

        .article-container {
          width: 100%;
          perspective: 1000px;
        }

        article {
          --bg-color: white;
          cursor: pointer;
          position: relative;
          background: var(--bg-color);
          height: 5rem;
          margin-left: -0.25rem;
          margin-right: 2.5rem;
          margin-top: 0.25rem;
          margin-bottom: 1.75rem;
          scroll-margin-top: 1rem;
          border-radius: 6px;
          padding: 0.25rem 0.5rem calc(0.25rem + 0.5px);
          padding-right: 4.5rem;
          box-shadow: 0px 3px 6px #00000029;
          hyphens: auto;
          hyphenate-limit-chars: 8 4 4;
          transition: --bg-color 0.2s, border-color 0.2s;
          border: 0.125rem solid transparent;

          @property --bg-color {
            syntax: '<color>';
            initial-value: white;
            inherits: false;
          }

          &.is-selected {
            --bg-color: #{mix($brand-color, white, 10%)};
            border-color: $brand-color;

            animation: flip-in 0.5s;
          }

          &.is-page:not(:has(img)) {
            padding-right: 0.75rem;
          }

          img {
            display: block;
            position: absolute;
            top: -1.4rem;
            right: -3rem;
            width: 8rem;
            height: calc(100% + 2.8rem);
            object-fit: contain;
            pointer-events: none;
            z-index: 1;
          }

          :global(.ripple-container) {
            border-radius: 6px;
          }

          .inside-container {
            overflow: hidden;
            max-height: 100%;

            &::after {
              content: '';
              position: absolute;
              right: 4.5rem;
              bottom: 0.25rem;
              height: 1.5rem;
              width: 4rem;
              background: linear-gradient(to left, var(--bg-color) 0%, var(--bg-color) 40%, transparent 100%);
            }
          }

          &.is-page:not(:has(img)) .inside-container::after {
            right: 0.75rem;
          }

          h6 {
            float: right;
            white-space: nowrap;
            margin: 0;
            margin-left: 1.5em;
          }

          h5 {
            text-transform: uppercase;
            margin-bottom: 0.25rem;
          }

          :global(.new-badge) {
            left: -0.35rem;
            top: -0.65rem;
          }
        }
      }

      nav.filter {
        position: absolute;
        top: -1px;
        right: $default-margin;
        width: 6rem;
        transform: translateY(-50%);
        z-index: 1;

        .filter-button {
          width: 100%;
          font-size: 0.75rem;
          padding: 0.5rem 0.75rem;
          background: white;
          border: 2px solid $brand-color;
          border-radius: 9999px;
          cursor: pointer;

          display: flex;
          justify-content: space-between;
          align-items: center;
          text-align: center;

          :global(.ripple-container) {
            border-radius: 9999px;
          }

          :global(svg) {
            vertical-align: middle;
            transform: rotate(90deg) translateX(-0.1em);
          }

          > * {
            display: inline-block;
          }

          > div:first-of-type:last-of-type {
            flex: 1;
          }
        }
      }
    }

    .cart-area {
      grid-area: cart;

      background: $field-bg;
      border-top: 2px solid $brand-color;
      transition: box-shadow 0.3s;

      background: white;
      box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;

      position: relative;
      z-index: 2;

      &::after {
        $fade-out-height: $default-margin;

        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: $fade-out-height;
        background: linear-gradient(to top,
          rgba(white, 0) 0%,
          rgba(white, 0.005) 2%,
          rgba(white, 0.01) 4%,
          rgba(white, 0.02) 6%,
          rgba(white, 0.035) 8%,
          rgba(white, 0.05) 10%,
          rgba(white, 0.125) 12%,
          rgba(white, 0.25) 14%,
          rgba(white, 0.4) 16%,
          rgba(white, 0.6) 20%,
          rgba(white, 0.75) 30%,
          rgba(white, 0.9) 40%,
          rgba(white, 0.95) 50%,
          rgba(white, 1) 75%,
          rgba(white, 1) 100%
        );

        background-repeat: no-repeat;

        pointer-events: none;
      }

      nav {
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
        padding: 0 $default-margin;
        transform: translateY(-50%);
        z-index: 1;
      }
    }

    &:has(.cart-editor) .cart-area {
      box-shadow: none;
    }

    .cart-editor {
      grid-area: 2 / 1 / span 1 / span 2; // details+menu
      display: grid;
      grid-template-columns: 1fr 42vw;
      border-top: 2px solid $brand-color;
      backdrop-filter: blur(4px);
      z-index: 2;

      overflow: hidden; // Prevent triangle from clipping with border

      margin-left: -24px; // To make the fly animation work correctly even with the backdrop filter

      > * {
        min-width: 0;
        min-height: 0;
      }

      .close-area {
        grid-area: 1 / 1 / span 1 / span 1;
      }

      .editor-inner-container {
        grid-area: 1 / 2 / span 1 / span 1;
        box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
        background: $field-bg;
        position: relative;

        $fade-out-height: 4rem;

        &::after {
          content: '';
          position: absolute;
          display: block;
          bottom: 0;
          left: 0;
          width: 100%;
          height: $fade-out-height;
          background: linear-gradient(to bottom,
            rgba($field-bg, 0) 0%,
            rgba($field-bg, 0.005) 2%,
            rgba($field-bg, 0.01) 4%,
            rgba($field-bg, 0.02) 6%,
            rgba($field-bg, 0.035) 8%,
            rgba($field-bg, 0.05) 10%,
            rgba($field-bg, 0.125) 12%,
            rgba($field-bg, 0.25) 14%,
            rgba($field-bg, 0.4) 16%,
            rgba($field-bg, 0.6) 20%,
            rgba($field-bg, 0.75) 30%,
            rgba($field-bg, 0.9) 40%,
            rgba($field-bg, 0.95) 50%,
            rgba($field-bg, 1) 75%,
            rgba($field-bg, 1) 100%
          );

          background-position-y: calc($fade-out-height * 0.25);
          background-repeat: no-repeat;

          pointer-events: none;
          z-index: 1;
        }

        .triangle {
          position: absolute;
          top: -9999px;
          right: -1px;
          width: 20px;
          height: 40px;
          clip-path: polygon(100% 0, 0 50%, 100% 100%);
          background: white;
          transition: top 0.05s;
          z-index: 4;
        }

        article {
          height: 100%;
          padding: $default-margin;
          padding-bottom: calc($fade-out-height * 0.75);
          overflow: auto;

          .close-float {
            float: right;
            margin-left: 0.5rem;
            margin-top: -0.5rem;
            margin-right: -0.5rem;
            margin-bottom: 0.5rem;
            display: flex;
            flex-direction: column;
            justify-content: end;
            align-items: center;
            gap: 0.25rem;

            a {
              color: currentColor;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0.5rem;
              z-index: 3;
              position: relative;

              :global(.ripple-container) {
                border-radius: 9999px;
              }
            }

            .small-product-image {
              width: 4.5rem;
              height: 4.5rem;
              margin-top: 0.5rem;
              margin-right: 0.5rem;
              background: $contrast-bg;
              border-radius: 6px;
              z-index: 2;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                transform: scale(1.25);
              }
            }
          }
        }
      }
    }

    .filter-overlay {
      grid-area: nav;
      background: $brand-color;
      color: white;
      padding: 0 $default-margin;
      position: relative;
      z-index: 0;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      justify-content: center;

      a.close {
        position: absolute;
        right: calc(12px - 0.5rem);
        top: calc(12px - 0.5rem);
        color: currentColor;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 0.5rem;
        position: absolute;
        width: calc(32px + 1rem);
        height: calc(32px + 1rem);

        :global(.ripple-container) {
          border-radius: 9999px;
        }
      }

      :global(.brand-button.is-primary) {
        border-color: white;

        padding-left: 10px;
        padding-right: 10px;
      }

      .filter-row {
        display: grid;
        grid-template-columns: calc(1.5em + 8px) 1fr calc(1.5em + 8px);

        .filter-text {
          grid-column: 2;
          text-align: center;
        }

        &:not(:has(.rounded-number)) .filter-text {
          grid-column: 1 / 4;
        }

        .rounded-number {
          grid-column: 3;
          text-align: right;
          justify-self: flex-end;

          padding-top: calc(0.33em - 3px);
        }
      }
    }
  }

  .rounded-number {
    display: inline-block;
    border-radius: 50%;
    border: 1px solid currentColor;
    width: 1.75em;
    height: 1.75em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 0.9em;
    padding-top: calc(0.5em - 3px);
    margin: calc(-0.45em + 2px) -2px;
  }

  // Animation: scale a bit and "flip inwards" (into the screen)
  @keyframes flip-in {
    0% {
      transform: scale(1) rotateX(0deg);
    }
    2% {
      transform: scale(0.98) rotateX(20deg);
    }
    100% {
      transform: scale(1) rotateY(0deg);
    }
  }
</style>

<svelte:window on:historyNavigation={() => setTimeout(() => selectArticle($selectedArticleId), 0)} on:backendWsMessage={event => { if (event.detail.type === 'queuedRobotCallError') robotCallError() }} />

<svelte:body on:touchend|capture={() => (navSnappy = true)} />

<FontSizeSelector />
<CustomerLanguageSelector />

<main>
  {#if currentlyEditingCartItem}
    <div class="product-image-area" transition:fade>
      <h1 class="title is-1">{$tC({ de: 'Bearbeiten', en: 'Edit' })}</h1>
    </div>
  {:else}
    <div class="product-image-area" on:click={e => { if ($selectedArticle?.image) doZoomImage(e, getMediaUrl($selectedArticle.image, 'large')) }} transition:fade>
      {#key $selectedArticle?.id}
        <!-- This trickery with the empty image and such is done to make sure the out transition works without global -->
        {#if ($selectedArticle && (!$selectedArticle.available || !$bundle.categories.some(category => category.available && category.articles.includes($selectedArticle))))}
          <div class="product-image" style:--bg={$selectedArticle?.image ? `url("${getMediaUrl($selectedArticle?.image, 'large')}")` : 'transparent'} class:is-hidden={!$selectedArticle?.image} in:fly|global={{ y: 30, delay: 300 }} out:fly={{ y: -30 }} data-unavailable={$tC({ de: 'Nicht verfügbar', en: 'Not Available' })} />
        {:else if isUnavailableForTakeaway($selectedArticle)}
          <div class="product-image" style:--bg={$selectedArticle?.image ? `url("${getMediaUrl($selectedArticle?.image, 'large')}")` : 'transparent'} class:is-hidden={!$selectedArticle?.image} in:fly|global={{ y: 30, delay: 300 }} out:fly={{ y: -30 }} data-unavailable={$tC({ de: 'Kein take-away', en: 'No take-away' })} />
        {:else}
          <div class="product-image" style:--bg={$selectedArticle?.image ? `url("${getMediaUrl($selectedArticle?.image, 'large')}")` : 'transparent'} class:is-hidden={!$selectedArticle?.image} in:fly|global={{ y: 30, delay: 300 }} out:fly={{ y: -30 }} />
        {/if}
      {/key}
    </div>
  {/if}

  <div class="nav-area">
    <nav in:fly|global={{ y: 30, delay: 100 }} on:touchmove={() => (navSnappy = false)} class:snappy={navSnappy} bind:this={navElement}>
      <div class="overscroller"></div>
      {#each categories as category (category.id)}
        {#if $minute && timeRestrictionMatches(category)}
          <a href={undefined} class:is-selected={$selectedCategoryId === category.id} on:click={() => selectCategory(category.id)} data-category-id={category.id} transition:slide class:is-unavailable={!category.available}>
            <span>
              {$tC(category.name)}
            </span>
            {#if category.newBadge}<NewBadge />{/if}
          </a>
        {/if}
      {/each}
      <div class="overscroller"></div>
    </nav>
  </div>

  <div class="logo-area">
    <img in:fade|global src="/images/logo.svg?_=2" alt="YaoOrder" on:click={() => selectArticle(null)} />
  </div>

  <div class="details-area" bind:this={detailsAreaElement}>
    {#if $history?.canGoBack}
      <div class="back-button" transition:fly={{ x: -10 }} on:click={() => $history.goBack()} use:rippleOnTouch><ArrowLeft /></div>
    {/if}
    {#if $history?.canGoForward}
      <div class="forward-button" transition:fly={{ x: 10 }} on:click={() => $history.goForward()} use:rippleOnTouch><ArrowRight /></div>
    {/if}
    {#key $selectedArticle?.id}
      <article in:fly|global={{ y: 30, delay: 400 }} out:fly={{ y: -30 }} use:handleOverscroll class:is-unavailable={$selectedArticle && (!$selectedArticle.available || !$bundle.categories.some(category => category.available && category.articles.includes($selectedArticle)) || isUnavailableForTakeaway($selectedArticle))} class:has-info-bar={callRobotOptionAvailable} on:scroll={e => e.target.closest('article').querySelectorAll('.ripple-container').forEach(container => container.remove())}>
        {#if $selectedArticle}
          <ArticleDetails articleId={$selectedArticle.id} tableType={$table?.type} bind:articleConfiguration on:change={() => saveArticleConfiguration(articleConfiguration)} bind:this={articleDetailsElTmp} />

          {#if !$selectedArticle.isPage}
            <div class="button-container">
              <BrandButtonRow>
                <!-- We have to consistently use MobileTooltip on *all* buttons to avoid sizing issues -->
                {#if !$isMenuPreviewMode}
                  <MobileTooltip active={false}><BrandButton class="is-fullwidth" toggle selected={$wishlist.some(item => item.article === $selectedArticle.id)} on:click={toggleWishlist}><Heart /> {$tC({ de: 'Vielleicht', en: 'Wishlist' })}</BrandButton></MobileTooltip>
                {/if}
                {#if $selectedArticle.allowOnlySingleQuantity && $cart.some(item => item.article === $selectedArticle.id)}
                  <MobileTooltip active={false}><BrandButton class="is-fullwidth" disabled><em>{$tC({ de: 'Nur 1× bestellbar!', en: 'Orderable only once!' })}</em></BrandButton></MobileTooltip>
                {:else}
                  <MobileTooltip active={!configValidation.isValid} label={!$selectedArticle.available ? $tC({ de: 'Artikel ist nicht verfügbar', en: 'Article is not available' }) : $tC({ de: 'Auswahl erforderlich', en: 'Selection required' })}><BrandButton class="is-fullwidth is-primary" disabled={!configValidation.isValid || $isMenuPreviewMode} on:click={addToCart}><ShoppingCart /> <strong>{$tC && formatCurrency(configValidation.finalPrice)}</strong></BrandButton></MobileTooltip>
                {/if}
              </BrandButtonRow>

              <Recommendations articles={recommendations} on:itemClick={e => selectArticle(e.detail.id)} />
            </div>
          {/if}
        {:else}
          <h3 class="title is-3">{$tC({ de: 'Bitte wählen Sie ein Produkt.', en: 'Please select a product.' })}</h3>
        {/if}
      </article>
    {/key}
  </div>

  <div class="menu-area">
    <nav class="menu" in:fly|global={{ y: 30, delay: 200 }} class:has-info-bar={callRobotOptionAvailable} bind:this={menuElement} use:handleOverscroll on:scroll={updateSelectedCategory} on:scrollend={onNavScrollEnd}>
      <div class="overscroller"></div>
      {#each categories as category (category.id)}
        {@const articles = category.articles.filter(article => !isHiddenForTakeaway(article))}
        {@const articlesMatchingFilter = articles.filter(article => $isMatchingFilter(article))}
        {#if $minute && timeRestrictionMatches(category)}
        <div class="category" data-category-id="{category.id}" transition:slide>
          {#if category.newBadge}<NewBadge />{/if}
          <h2 class="title is-2" class:is-unavailable={!category.available}>{$tC(category.name)}</h2>
          {#if category.timeRestriction}
            <div class="category-subtitle">{$tC({ de: 'Von {start} bis {end} Uhr', en: 'From {start} to {end}' }, { start: formatTime(category.timeRestrictionStart), end: formatTime(category.timeRestrictionEnd) })}</div>
          {/if}
          {#if articlesMatchingFilter.length !== articles.length}
            <div class="category-subtitle">{$tC({ de: '{c} Artikel ausgefiltert', en: `{c} article${articles.length - articlesMatchingFilter.length === 1 ? '' : 's'} filtered out` }, { c: articles.length - articlesMatchingFilter.length })}</div>
          {/if}

          {#each articlesMatchingFilter as article (article.id)}
            <div class="article-container" on:click={() => selectArticle(article.id, true)}>
              <article class:is-selected={$selectedArticleId === article.id} data-article-id={article.id} class:is-page={article.isPage} data-unavailable={ $tC && getMenuItemUnavailabilityStatus(article, category) } use:rippleOnTouch>
                {#if article.newBadge || category.newBadge}<NewBadge />{/if}

                {#if article.image}
                  <img src={getMediaUrl(article.image, 'large')} alt={$tC(article.name)} />
                {/if}
                <div class="inside-container">
                  {#if article.minPrice}
                    <h6 class="title is-6">{article.minPrice !== article.maxPriceFromRequiredGroups ? $tC({ de: 'ab ', en: 'from ' }) : ''}{$tC && formatCurrency(article.minPrice)}</h6>
                  {/if}
                  <h5 class="title is-5">{$tC(article.name)}</h5>
                  <div class="description">{$tC(article.shortDescription)?.trim() || getDescriptionPreview($tC(article.description))}</div>
                </div>
              </article>
            </div>
          {/each}
        </div>
        {/if}
      {/each}
      <div class="overscroller"></div>
    </nav>

    <nav class="filter">
      {#if !filterOpen && !currentlyEditingCartItem}
        <div class="filter-button" transition:flatten use:rippleOnTouch on:click={() => (filterOpen = true)}>
          <div><Sliders class="rotate-90" size="tiny" width="1px" /> {$tC({ de: 'Filter', en: 'Filter' })}</div>
          {#if totalFilterCount}
            <div class="rounded-number">{totalFilterCount}</div>
          {/if}
        </div>
      {/if}
    </nav>
  </div>

  <div class="cart-area">
    {#if !$isMenuPreviewMode}
      <nav>
        <PillTabs tabs={['cart', 'wishlist']} bind:currentTab={selectedCartView} let:tab>
          {#if tab === 'cart'}
            <ShoppingCart size="tiny" width="1px" /> {$tC({ de: 'Warenkorb', en: 'Shopping Cart' })}
          {:else if tab === 'wishlist'}
            <Heart size="tiny" width="1px" /> {$tC({ de: 'Vielleicht', en: 'Wishlist' })}
          {/if}
        </PillTabs>
      </nav>
    {/if}

    <CartView bind:type={selectedCartView} on:itemClick={onCartItemClick} on:change={() => saveCartChange()} on:addExtraItemToCart={e => addExtraItemToCart(e.detail.item)} />
  </div>

  {#if callRobotOptionAvailable}
    <div class="info-bar" in:fly|global={{ y: 50, delay: 300 }} out:fly={{ y: 50 }}>
      {#if $second && $table?.robotTaskId !== 'queued' && new Date($session.lastRobotCall ?? 0) < Date.now() - 120000}
        <a href={undefined} use:rippleOnTouch on:click={callRobot} in:scale|global>
          <Robot />
          <span>{$tC({ de: 'Abservier-Roboter rufen', en: 'Call dish-collecting robot' })}</span>
        </a>
      {:else}
        <span in:scale|global style:opacity={0.4}>{$tC({ de: 'Roboter wurde gerufen', en: 'Robot has been called' })}<span class="dot-dot-dot"></span></span>
      {/if}
    </div>
  {/if}

  {#if currentlyEditingCartItem}
    <div class="cart-editor" transition:fly={{ x: 24 }}>
      <div class="close-area" on:click={closeCartItemEditor} />
      {#key currentlyEditingCartItem.uuid}
        <div class="editor-inner-container" transition:fly={{ x: 24 }}>
          <div class="triangle" use:positionTriangle></div>
          <article use:handleOverscroll>
            <div class="close-float">
              <a href={undefined} on:click={closeCartItemEditor} use:rippleOnTouch>{$tC({ de: 'schließen', en: 'close' })} <X size="large" linecap="square" linejoin="square" /></a>
              {#if $bundle.articles[currentlyEditingCartItem.article]?.image}
                <div class="small-product-image"><img src={getMediaUrl($bundle.articles[currentlyEditingCartItem.article].image, 'large')} on:click={e => doZoomImage(e, getMediaUrl($bundle.articles[currentlyEditingCartItem.article].image, 'large'))} alt={$tC({ de: 'Bild', en: 'Image' })} /></div>
              {/if}
            </div>
            <ArticleDetails mode="cart" articleId={currentlyEditingCartItem.article} articleConfiguration={currentlyEditingCartItem} on:change={() => updateCartItem(currentlyEditingCartItem)} hasErrors={!cartEditingValidation.isValid} />
            <hr />
            <div class="level mb-3">
              <div class="level-left">
                <div class="level-item"><strong>{$tC({ de: 'Einzelpreis:', en: 'Single price:' })}</strong></div>
              </div>
              <div class="level-right">
                <div class="level-item">{$tC && formatCurrency(cartEditingValidation.finalPrice)}</div>
              </div>
            </div>
            <BrandButtonRow>
              <div style:flex="1" class="level my-0 mr-3">
                <div class="level-left">
                  <div class="level-item"><strong>{currentlyEditingCartItem.quantity}&times;</strong>&nbsp;{$tC({ de: 'im Warenkorb', en: 'in cart' })}</div>
                </div>
                <div class="level-right">
                  <div class="level-item"><strong>{$tC && formatCurrency(cartEditingValidation.finalPrice * currentlyEditingCartItem.quantity)}</strong></div>
                </div>
              </div>
              <BrandButton class="is-primary" icon on:click={() => updateEditorQuantity(currentlyEditingCartItem.quantity + 1)} disabled={$bundle.articles[currentlyEditingCartItem.article]?.allowOnlySingleQuantity}><Plus /></BrandButton>
              <BrandButton class="is-primary" icon on:click={() => updateEditorQuantity(currentlyEditingCartItem.quantity - 1)}><Minus /></BrandButton>
            </BrandButtonRow>
            {#if $bundle.articles[currentlyEditingCartItem.article]?.allowOnlySingleQuantity}
              <BrandButton class="is-fullwidth mt-3" disabled><em>{$tC({ de: 'Nur 1× bestellbar!', en: 'Orderable only once!' })}</em></BrandButton>
            {:else}
              <BrandButton class="is-fullwidth mt-3" on:click={copyConfig}><Copy /> {$tC({ de: 'Neue ähnliche Bestellung', en: 'New similar order' })}</BrandButton>
            {/if}
          </article>
        </div>
      {/key}
    </div>
  {/if}

  {#if filterOpen}
    <div class="filter-overlay" transition:fly={{ y: 30 }}>
      <p>{$tC({ de: 'Filter:', en: 'Filter:' })}</p>

      <BrandButton class="is-fullwidth is-primary" on:click={() => dialogs.open(DietFilterDialog)}>
        <div class="filter-row">
          <div class="filter-text">{$tC({ de: 'Ernährung:', en: 'Diet:' })} {$tC(getOptionLabel(diets, $userData?.dietFilter ?? 'omnivore'))}</div>
          {#if dietFilterCount}
            <div class="rounded-number">{dietFilterCount}</div>
          {/if}
        </div>
      </BrandButton>
      <BrandButton class="is-fullwidth is-primary" on:click={() => dialogs.open(AllergenFilterDialog)}>
        <div class="filter-row">
          <div class="filter-text">{$tC({ de: 'Allergene ausschließen', en: 'Exclude Allergens' })}</div>
          {#if allergenFilterCount}
            <div class="rounded-number">{allergenFilterCount}</div>
          {/if}
        </div>
      </BrandButton>

      <a class="close" href={undefined} on:click={() => (filterOpen = false)} use:rippleOnTouch><X size="large" linecap="square" linejoin="square" /></a>
    </div>
  {/if}
</main>
